import {Injectable} from '@angular/core'
import {CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router'
import {AuthService} from 'app/c2-core/services/auth.service'

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const isSystem = await this.authService.isSystem()
      if (isSystem) {
        this.router.navigate(['/pages/system'])
        return false
      }

      const isAdmin = await this.authService.isAdmin()
      if (isAdmin) {
        this.router.navigate(['/pages/dashboard'])
        return false
      }

      const isActionCenter = await this.authService.isActionCenter()
      if (isActionCenter) {
        this.router.navigate(['/pages/actions'])
        return false
      }
    } catch (e) {
      throw e
    }
    return false
  }
}
