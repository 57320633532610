import {BaseModel} from '../../base/base.model'

export class Role extends BaseModel {
  name: string
  displayName: string
  assignable: boolean

  public static readonly ActionCenter: string = 'ActionCenterUser'

  constructor(role: any) {
    super()
    this.copy(role)
  }

  copy(that: any) {
    if (that != null && that !== undefined) {
      this.name = super.safeValueOrNull(that.name)
      this.assignable = super.safeValueOrNull(that.assignable)
      // NOTE: change below after spelling is changed in admin tool
      this.displayName = super.safeValueOrNull(that.displanName)
    }
  }

  toJSON() {
    return {
      name: this.name,
      assignable: this.assignable,
    }
  }
}
