import {Injectable} from '@angular/core'
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore'
import {Observable} from 'rxjs'

@Injectable()
export class DataServiceFirestore {
  constructor(private afs: AngularFirestore) {
    afs.firestore.settings({})
  }

  /**
   * Create a reference to a collection, does not
   * go to server or create/read anything phsical
   *
   * @param path
   */
  public collectionRef<T>(path): AngularFirestoreCollection<T> {
    return this.afs.collection(path)
  }

  /**
   * Create a reference to a document, does not
   * go to server or create/read anything phsical
   *
   * @param path
   */
  public documentRef<T>(path): AngularFirestoreDocument<T> {
    return this.afs.doc(path)
  }

  /**
   * Create an ID to be used as a key
   *
   */
  public createId(): string {
    return this.afs.createId()
  }

  /**
   * Fetch data as observable from a collection with a callback to build a query
   *
   * @param path
   * @param queryFn
   */
  public fetch<T>(path: string, queryFn): Observable<Array<T>> {
    let query = this.afs.collection<T>(path, ref => queryFn(ref))
    return query.valueChanges()
  }

  /**
   * Fetch data as promise from a collection with a callback to build a query
   * @param path
   * @param queryFn
   */
  public fetchOnce<T>(path: string, queryFn): Promise<any> {
    let ref = this.afs.collection(path).ref
    ref = queryFn(ref)

    return ref
      .get()
      .then(snap => {
        let items = new Array<any>()
        snap.forEach(item => {
          items.push(item.data())
        })
        return Promise.resolve(items)
      })
      .catch(err => Promise.reject(err))
  }

  /**
   * Get a list of all documents in a collection
   *
   * @param path
   */
  public listCollection<T>(path: string, orderBy?: string, sortDirection?: string): Observable<Array<T>> {
    let refOrQuery = this.afs.collection<T>(path, ref => {
      let query = null
      if (orderBy) {
        if (sortDirection && sortDirection === 'asc') {
          query = ref.orderBy(orderBy, 'asc')
        } else {
          query = ref.orderBy(orderBy, 'desc')
        }
      }
      return query || ref
    })
    return refOrQuery.valueChanges()
  }

  /**
   * Add a document to a collection
   *
   *
   * @param path
   * @param document
   */
  public addDocument(path: string, document: any): Promise<string> {
    const id = this.afs.createId()
    document.id = id
    return this.afs
      .collection(path)
      .doc(id)
      .set(document)
      .then(() => {
        return Promise.resolve(id)
      })
      .catch(err => Promise.reject(err))
  }

  /**
   * Add a document with a specified id
   *
   *
   * @param collection
   * @param id
   * @param document
   */
  public addDocumentWithId(collection: string, id: string, document: any): Promise<void> {
    return this.afs
      .collection(collection)
      .doc(id)
      .set(document)
      .catch(err => Promise.reject(err))
  }

  /**
   * Destructive create/save of a document
   *
   *
   * @param path
   * @param document
   */
  public setDocument(path: string, document: any): Promise<void> {
    return this.afs
      .doc(path)
      .set(document)
      .catch(err => Promise.reject(err))
  }

  public setDocumentWithMerge(path: string, document: any): Promise<void> {
    return this.afs
      .doc(path)
      .set(document, {merge: true})
      .catch(err => Promise.reject(err))
  }

  /**
   * Update a document, ok to pass partial info. The document will be merged.
   *
   *
   * @param path
   * @param document
   */
  public updateDocument(path: string, document: any): Promise<void> {
    return this.afs
      .doc(path)
      .update(document)
      .catch(err => Promise.reject(err))
  }

  /**
   * Get a document and cast to type <T>
   *
   *
   * @param path
   */
  public getDocument<T>(path: string): Observable<any> {
    return this.afs.doc<T>(path).valueChanges()
  }

  public getDocumentOnce<T>(collection, docId): Promise<any> {
    return this.afs
      .collection(collection)
      .doc(docId)
      .ref.get()
      .then(snap => {
        return Promise.resolve(snap.data())
      })
      .catch(err => Promise.reject(err))
  }
  /**
   * Delete a document
   *
   *
   * @param path
   */
  public deleteDocument(path: string): Promise<any> {
    return this.afs
      .doc(path)
      .delete()
      .catch(err => Promise.reject(err))
  }

  /**
   * Insert and array of documents with an atomic batch write
   *
   * @param collectionRef
   * @param documents
   */
  public batchInsert<T>(collectionRef: AngularFirestoreCollection<T>, documents: any[]): Promise<void> {
    const batch = this.afs.firestore.batch()
    documents.forEach(docToAdd => {
      const id = this.createId()
      const docRef = collectionRef.doc(id)
      docToAdd.id = id
      batch.set(docRef.ref, docToAdd)
    })
    return batch.commit().catch(err => Promise.reject(err))
  }
}
