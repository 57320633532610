// http://emailregex.com/
export const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const SYSTEM_ROLES = {
  administrator: 'Administrator',
  sme: 'SubjectMatterExpert',
  actionCenterUser: 'ActionCenterUser',
  policyEditor: 'PolicyEditor',
  assignmentEditor: 'AssignmentEditor',
  procedureEdtior: 'ProcedureEditor',
  system: 'SystemUser',
}

export const ROLE_MAP = 'roleMap.' // Ex: const SME = ROLE_MAP + SYSTEM_ROLES.sme;
