import {Injectable} from '@angular/core'
import {Observable} from 'rxjs/Observable'

import {DataServiceFirebase} from './data.firebase.service'
import {DataServiceFirestore} from './data.firestore.service'
import {DataSchema} from './data.schema'
import {ConfigService} from './config.service'
import {AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore'

@Injectable()
export class DataService extends DataSchema {
  err: Error = new Error('This feature is currently unavailable due to system maintance, please try again in a few minutes.')

  constructor(private firebase: DataServiceFirebase, private firestore: DataServiceFirestore, private configService: ConfigService) {
    super()
  }

  public isReadOnlyMode(): boolean {
    return this.configService.getReadOnlyMode()
  }

  /**
   * Create a unique key for a document
   *
   */
  public createId(): string {
    return this.firestore.createId()
  }

  /**
   * Finds a list
   *
   * @param path
   * @param opts -- optional for params on the query
   *
   * @returns {Observable<any>}
   */
  public list(path: string, orderBy?: any, equalTo?: any): Observable<any> {
    return this.firebase.list(path, orderBy, equalTo)
  }

  /**
   * Create a reference to a collection, does not
   * go to server or create/read anything phsical
   *
   * @param path
   */
  public collectionRef<T>(path): AngularFirestoreCollection<T> {
    return this.firestore.collectionRef(path)
  }

  /**
   * Create a reference to a document, does not
   * go to server or create/read anything phsical
   *
   * @param path
   */
  public documentRef<T>(path): AngularFirestoreDocument<T> {
    return this.firestore.documentRef(path)
  }

  /**
   * Fetch data as observable from a collection with a callback to build a query
   * NOTE: Use this instead of queryDocuments()
   *
   * @param path
   * @param queryFn
   */
  public fetch<T>(path: string, queryFn): Observable<Array<T>> {
    return this.firestore.fetch(path, queryFn)
  }

  /**
   * Fetch data as promise from a collection with a callback to build a query
   * NOTE: Use this instead of queryDocumentsOnce()
   * @param path
   * @param queryFn
   */
  public fetchOnce<T>(path: string, queryFn): Promise<any> {
    return this.firestore.fetchOnce(path, queryFn).catch(err => Promise.reject(err))
  }

  /**
   * Get a list of all documents in a collection
   *
   * @param path
   */
  public listCollection<T>(path: string, orderBy?: string, sortDirection?: string): Observable<Array<T>> {
    return this.firestore.listCollection(path, orderBy, sortDirection)
  }

  /**
   * Adds a document to a collection
   *
   *
   * @param path
   * @param document
   */
  public addDocument(path: string, document: any): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.addDocument(path, document).catch(err => Promise.reject(err))
  }

  /**
   * Add a document with a specified id
   *
   *
   * @param collection
   * @param id
   * @param document
   */
  public addDocumentWithId(collection: string, id: string, document: any): Promise<void> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.addDocumentWithId(collection, id, document).catch(err => Promise.reject(err))
  }

  /**
   * Destructive create/save of a document
   *
   *
   * @param path
   * @param document
   */
  public setDocument(path: string, document: any): Promise<void> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.setDocument(path, document).catch(err => Promise.reject(err))
  }

  public setDocumentWithMerge(path: string, document: any): Promise<void> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.setDocumentWithMerge(path, document).catch(err => Promise.reject(err))
  }

  /**
   * Update a document, ok to pass partial info. The document will be merged.
   *
   *
   * @param path
   * @param document
   */
  public updateDocument(path: string, document: any): Promise<void> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.updateDocument(path, document).catch(err => Promise.reject(err))
  }

  /**
   * Insert and array of documents with an atomic batch write
   *
   *
   * @param collectionRef
   * @param documents
   */
  public batchInsert<T>(collectionRef: AngularFirestoreCollection<T>, documents: any[]): Promise<void> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.batchInsert(collectionRef, documents).catch(err => Promise.reject(err))
  }

  /**
   * Get a document and cast to type (T)
   * @param path
   */
  public getDocument<T>(path: string): Observable<any> {
    return this.firestore.getDocument<T>(path)
  }

  /**
   * Get a document with a Promise
   */
  public getDocumentOnce<T>(collection, docId): Promise<any> {
    return this.firestore.getDocumentOnce(collection, docId)
  }

  /**
   * Delete a document
   *
   *
   * @param path
   */
  public deleteDocument(path: string): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firestore.deleteDocument(path).catch(err => Promise.reject(err))
  }

  /**
   * Finds an object
   * @param path
   *
   * @returns {Observable<any>}
   */
  public findObject(path: string): Observable<any> {
    return this.firebase.findObject(path)
  }

  /**
   * Finds an object 'once' without subscription, the return
   * value from the promise is a snapshot and val() is required
   * to get the object. ex:
   *  findObjectOnce(path).then( obj => {
   *      myobject = obj.val();
   *  });
   *
   * @param path
   *
   * @returns {Promise<any>}
   */
  public findObjectOnce(path: string): Promise<any> {
    return this.firebase.findObjectOnce(path).catch(err => Promise.reject(err))
  }

  /**
   * Puts an object to [path] using the supplied key and object,
   * this is useful for items where a relation exists such as
   * the user profile, where the key to the object should be the
   * Firebase uid, or a policy body should have a key identical to
   * the policy header.
   *
   * @param path
   * @param key
   * @param object
   *
   * @returns {Promise<any>}
   *
   * @see {firebase.database.Reference.set}
   */
  public putObjectWithKey(path: string, key: string, object: any): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.putObjectWithKey(path, key, object).catch(err => Promise.reject(err))
  }

  /**
   * Updates an object.
   *
   * @param path
   * @param obj
   *
   * @returns {Promise<any>}
   */
  public saveObject(path: string, obj: any): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.saveObject(path, obj).catch(err => Promise.reject(err))
  }

  /**
   * Updates an array (object)
   *
   *
   * @param path
   * @param object
   */
  public saveArrayObject(path: string, object: any): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.saveArrayObject(path, object).catch(err => Promise.reject(err))
  }

  /**
   * Removes an object from path
   *
   * @param path
   */
  public removeObject(path: string): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.removeObject(path).catch(err => Promise.reject(err))
  }

  /**
   * Push/Persist an object to a location that is normally
   * used as a list
   *
   * @param path
   * @param obj
   */
  public push(path: string, obj: any): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.push(path, obj).catch(err => Promise.reject(err))
  }

  /**
   * Remove an object from a location that is normally
   * used as a list
   *
   * @param path
   * @param key
   */
  public remove(path: string, key: string): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.remove(path, key).catch(err => Promise.reject(err))
  }

  public removeList(path: string): Promise<any> {
    if (this.isReadOnlyMode()) {
      return Promise.reject(this.err)
    }
    return this.firebase.removeList(path).catch(err => Promise.reject(err))
  }
}
