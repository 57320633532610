import {BaseModel} from '../../base/base.model'
import {SYSTEM_ROLES} from 'app/c2-core/core.constants'

export class CustomerUser extends BaseModel {
  customerKey: string
  firstname: string
  lastname: string
  roles: Array<string>
  jobFunction: string
  id: string
  email: string
  disabled: Date = null
  isDisabled: boolean
  authId: string
  uploadLogId: string
  state: string
  invited: Date
  registered: Date
  termsVersionAccepted: number
  originSource: string
  lookupKey: string
  roleMap: any

  // Transients //
  resendInvite: boolean = false
  touched: boolean = false

  readonly ORIGIN_SOURCE_WEB: string = 'web'
  readonly ORIGIN_SOURCE_UPLOAD: string = 'upload'

  readonly USER_STATE_DISABLED: string = 'Disabled'
  readonly USER_STATE_INVITED: string = 'Invited'
  readonly USER_STATE_REGISTERED: string = 'Registered'
  readonly USER_STATE_UPLOADED: string = 'Uploaded'

  constructor(user: any) {
    super()
    this.copy(user)
  }

  get name(): string {
    return this.firstname + ' ' + this.lastname
  }

  copy(that: any) {
    if (that != null && that !== undefined) {
      this.customerKey = super.safeValueOrNull(that.customerKey)
      this.firstname = super.safeValueOrNull(that.firstname)
      this.lastname = super.safeValueOrNull(that.lastname)
      this.roles = that.roles ? that.roles : new Array<string>()
      this.jobFunction = super.safeValueOrNull(that.jobFunction)
      this.id = super.safeValueOrNull(that.id)
      this.email = super.safeValueOrNull(that.email)
      this.disabled = super.fromFirestoreDate(that.disabled)
      this.isDisabled = super.safeValueOrNull(that.isDisabled)
      this.authId = super.safeValueOrNull(that.authId)
      this.resendInvite = super.safeValueOrNull(that.resendInvite)
      this.uploadLogId = super.safeValueOrNull(that.uploadLogId)
      this.state = super.safeValueOrNull(that.state)
      this.invited = super.fromFirestoreDate(that.invited)
      this.registered = super.fromFirestoreDate(that.registered)
      this.termsVersionAccepted = super.safeValueOrNull(that.termsVersionAccepted)
      this.originSource = super.safeValueOrNull(that.originSource)
      this.lookupKey = super.safeValueOrNull(that.lookupKey)
    }
  }

  addRole(role: string) {
    if (this.roles == null || this.roles === undefined || this.roles.length < 1) {
      this.roles = new Array<string>()
    } else if (this.hasRole(role)) {
      return
    }

    this.roles.push(role)
  }

  removeRole(role: string) {
    if (!this.hasRole(role)) {
      return
    }

    this.roles.splice(this.roles.indexOf(role), 1)
  }

  hasRole(role: string): boolean {
    if (this.roles == null || this.roles === undefined || this.roles.length < 1) {
      return false
    }
    let found: boolean = false
    this.roles.forEach(r => {
      if (r == role) {
        found = true
        return
      }
    })

    return found
  }

  isActionCenterOnly() {
    return this.roles.length === 1 && this.hasRole(SYSTEM_ROLES.actionCenterUser)
  }

  isSystemUser() {
    return this.hasRole(SYSTEM_ROLES.system)
  }

  isAdministrativeOnly() {
    return !this.hasRole(SYSTEM_ROLES.actionCenterUser)
  }

  hasJobFunctionIn(jfs: string[]): boolean {
    return jfs.includes(this.jobFunction)
  }

  isRegistered() {
    return this.USER_STATE_REGISTERED === this.state
  }
  isInvited() {
    return this.USER_STATE_INVITED === this.state
  }
  isUploaded() {
    return this.USER_STATE_UPLOADED === this.state
  }
  isAdmin() {
    return this.hasRole(SYSTEM_ROLES.administrator)
  }

  /**
   * Comapres another object (that) to this
   * returns false if any field is not identical
   */
  compareTo(that: any) {
    if (
      !super.compare(this.firstname, that.firstname) ||
      !super.compare(this.lastname, that.lastname) ||
      !super.compare(this.email, that.email) ||
      !super.compare(this.jobFunction, that.jobFunction)
    ) {
      return false
    }

    return true
  }

  toJSON() {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      roles: this.roles,
      jobFunction: this.jobFunction,
      customerKey: this.customerKey,
      id: this.id,
      disabled: this.disabled || null,
      isDisabled: this.isDisabled || false,
      authId: this.authId,
      resendInvite: this.resendInvite,
      uploadLogId: this.uploadLogId,
      invited: this.invited || null,
      registered: this.registered || null,
      termsVersionAccepted: this.termsVersionAccepted || null,
      originSource: this.originSource || null,
      lookupKey: this.lookupKey,
      roleMap: this.roleMap,
    }
  }
}
