import {NgModule, ModuleWithProviders} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ReactiveFormsModule, FormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {NgUploaderModule} from 'ngx-uploader'
import {AppTranslationModule} from '../app.translation.module'

import {BaThemeConfig} from './theme.config'

import {BaThemeConfigProvider} from './theme.configProvider'

import {BaBackTop, BaCard, BaContentTop, BaMenuItem, BaMenu, BaPageTop, BaPictureUploader, BaSidebar} from './components'

import {BaCardBlur} from './components/baCard/baCardBlur.directive'

import {BaScrollPosition, BaSlimScroll, BaThemeRun} from './directives'

import {BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe} from './pipes'

import {BaImageLoaderService, BaMenuService, BaThemePreloader, BaThemeSpinner} from './services'

import {EmailValidator, EqualPasswordsValidator} from './validators'
import {CustomerAPI} from 'app/c2-core/apis/customer/customers.api'

const NGA_COMPONENTS = [BaBackTop, BaCard, BaContentTop, BaMenuItem, BaMenu, BaPageTop, BaPictureUploader, BaSidebar]

const NGA_DIRECTIVES = [BaScrollPosition, BaSlimScroll, BaThemeRun, BaCardBlur]

const NGA_PIPES = [BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe]

const NGA_SERVICES = [BaImageLoaderService, BaThemePreloader, BaThemeSpinner, BaMenuService]

const NGA_VALIDATORS = [EmailValidator, EqualPasswordsValidator]

@NgModule({
  declarations: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, AppTranslationModule, NgUploaderModule, CustomerAPI.forRoot()],
  exports: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
})
export class NgaModule {
  static forRoot(): ModuleWithProviders<NgaModule> {
    return {
      ngModule: NgaModule,
      providers: [BaThemeConfigProvider, BaThemeConfig, ...NGA_VALIDATORS, ...NGA_SERVICES],
    }
  }
}
