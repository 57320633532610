import {OnInit, Component, ViewContainerRef} from '@angular/core'
import * as $ from 'jquery'

import {GlobalState} from './global.state'
import {BaImageLoaderService, BaThemePreloader, BaThemeSpinner} from './theme/services'
import {BaThemeConfig} from './theme/theme.config'
import {layoutPaths} from './theme/theme.constants'

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  styleUrls: ['./app.component.scss'],
  template: `
    <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `,
})
export class App {
  isMenuCollapsed: boolean = false

  constructor(
    private _state: GlobalState,
    private _imageLoader: BaImageLoaderService,
    private _spinner: BaThemeSpinner,
    private viewContainerRef: ViewContainerRef,
    private themeConfig: BaThemeConfig
  ) {
    // Fix for firebase bug
    localStorage.removeItem('firebase:previous_websocket_failure')

    themeConfig.config()

    this._state.subscribe('menu.isCollapsed', isCollapsed => {
      this.isMenuCollapsed = isCollapsed
    })
  }

  public ngAfterViewInit(): void {
    // hide spinner once all loaders are completed
    BaThemePreloader.load().then(values => {
      this._spinner.hide()
    })
  }

  private _loadImages(): void {
    // register some loaders
    //BaThemePreloader.registerLoader(this._imageLoader.load('/assets/img/sky-bg.jpg'));
  }
}
