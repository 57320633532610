import {Injectable} from '@angular/core'
import {Observable} from 'rxjs/Observable'
import {DataService} from '../../../services/data.service'

import {BaseService} from '../../base/base.service'
import {Customer} from '../models/customer.model'
import {CustomerLocation} from '../models/location.model'
import {CustomerContact} from '../models/contact.model'
import {map} from 'rxjs/operators'

@Injectable()
export class CustomerService extends BaseService {
  constructor(private dataService: DataService) {
    super()
  }

  /**
   * List all customers
   *
   *
   */
  public list(): Observable<Array<Customer>> {
    return this.dataService.listCollection(this.dataService.COLLECTION_CUSTOMERS, 'name').pipe(map(list => super.mapList(list, Customer)))
  }

  /**
   * Find a customer
   *
   *
   * @param customerKey
   */
  public find(customerKey: string): Observable<Customer> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey
    return this.dataService.getDocument(path).pipe(map(obj => super.mapObject(obj, Customer, path)))
  }

  /**
   * Create a customer
   *
   *
   * @param customer
   */
  public add(customer: Customer): Promise<string> {
    const id: string = this.dataService.createId()
    customer.customerKey = id
    customer.effectiveDate = new Date()
    return this.dataService
      .addDocumentWithId(this.dataService.COLLECTION_CUSTOMERS, id, customer.toJSON())
      .then(() => {
        return Promise.resolve(id)
      })
      .catch(err => Promise.reject(err))
  }

  /**
   * Save a customer
   *
   *
   * @param customer
   */
  public save(customer: Customer): Promise<any> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customer.customerKey
    return this.dataService.setDocument(path, customer.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * List all locations
   *
   *
   * @param customerKey
   */
  public listLocations(customerKey: string): Observable<Array<CustomerLocation>> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_LOCATIONS
    return this.dataService.listCollection(path, 'primaryName', 'asc').pipe(map(list => super.mapList(list, CustomerLocation)))
  }

  /**
   * Add a location
   *
   *
   * @param customerKey
   * @param location
   */
  public addLocation(customerKey: string, location: CustomerLocation): Promise<void> {
    if (!customerKey) {
      throw new Error('Customer Key is requried.')
    }
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_LOCATIONS
    const id = this.dataService.createId()
    location.id = id
    return this.dataService.addDocumentWithId(path, id, location.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * Save a location
   *
   *
   * @param customerKey
   * @param location
   */
  public saveLocation(customerKey: string, location: CustomerLocation): Promise<void> {
    if (!customerKey) {
      throw new Error('Customer Key is requried.')
    }
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_LOCATIONS + location.id
    return this.dataService.setDocument(path, location.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * Delete a location
   *
   *
   * @param customerKey
   * @param location
   */
  public deleteLocation(customerKey: string, location: CustomerLocation): Promise<void> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_LOCATIONS + location.id
    return this.dataService.deleteDocument(path).catch(err => Promise.reject(err))
  }

  /**
   * List all contacts
   *
   *
   * @param customerKey
   */
  public listContacts(customerKey: string): Observable<Array<CustomerContact>> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_CONTACTS
    return this.dataService.listCollection(path, 'firstName', 'asc').pipe(map(list => super.mapList(list, CustomerContact)))
  }

  /**
   * Add a contact
   *
   *
   * @param customerKey
   * @param contact
   */
  public addContact(customerKey: string, contact: CustomerContact): Promise<void> {
    if (!customerKey) {
      throw new Error('Customer Key is requried.')
    }
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_CONTACTS
    const id = this.dataService.createId()
    contact.id = id
    return this.dataService.addDocumentWithId(path, id, contact.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * Save a contact
   *
   *
   * @param customerKey
   * @param contact
   */
  public saveContact(customerKey: string, contact: CustomerContact): Promise<void> {
    if (!customerKey) {
      throw new Error('Customer Key is requried.')
    }
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_CONTACTS + contact.id
    return this.dataService.setDocument(path, contact.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * Delete a contact
   *
   *
   * @param customerKey
   * @param contact
   */
  public deleteContact(customerKey: string, contact: CustomerContact): Promise<void> {
    const path: string = this.dataService.COLLECTION_CUSTOMERS + customerKey + this.dataService.COLLECTION_CUSTOMER_CONTACTS + contact.id
    return this.dataService.deleteDocument(path).catch(err => Promise.reject(err))
  }
}
