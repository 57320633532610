import {NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, InjectionToken, Injectable, Injector} from '@angular/core'
import {BrowserModule, Title} from '@angular/platform-browser'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {HttpClientModule} from '@angular/common/http'
import {RouterModule} from '@angular/router'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'

/*
 * Platform and Environment providers/directives/pipes
 */
import {routing} from './app.routing'

// App is our top level component
import {AppState, InternalStateType} from './app.service'
import {App} from './app.component'
import {GlobalState} from './global.state'

import {NgaModule} from './theme/nga.module'
import {PagesModule} from './pages/pages.module'

import {AngularFireModule} from '@angular/fire'
import {AngularFireDatabaseModule} from '@angular/fire/database'
import {AngularFireAuthModule} from '@angular/fire/auth'
import {AngularFirestoreModule} from '@angular/fire/firestore'
import {AngularFireStorageModule} from '@angular/fire/storage'
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics'

import {AuthGuard} from './providers/auth.guard'
import {RoleGuard} from './providers/role.guard'
import {RedirectGuard} from './providers/redirect.guard'

import {SharedModule} from './shared/shared.module'
import {C2CoreModule} from './c2-core/c2core.module'
import {UserAPI} from 'app/c2-core/apis/user/user.api'

import {environment} from '../environments/environment'

import {ConfigService} from './c2-core/services/config.service'
import {C2ErrorHandler, RollbarService, rollbarFactory} from './app.errorhandler'
import {UiButtonModule} from '@engineering11/ui-button'
import { UiToastModule } from '@engineering11/ui-toast'

// Application wide providers
const APP_PROVIDERS = [AppState, GlobalState]

export type StoreType = {
  state: InternalStateType
  restoreInputValues: () => void
  disposeOldHosts: () => void
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [App],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    NgbModule,
    PagesModule,
    routing,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    SharedModule.forRoot(),
    C2CoreModule.forRoot(),
    UserAPI.forRoot(),
    UiButtonModule,
    UiToastModule.forRoot(),
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    Title,
    AuthGuard,
    RoleGuard,
    RedirectGuard,
    ConfigService,
    ScreenTrackingService,
    UserTrackingService,
    //{provide: ErrorHandler, useClass: ErrorHandlerHook},
    {provide: ErrorHandler, useClass: C2ErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(public appState: AppState, public configService: ConfigService) {}
}
