import {NgModule, ModuleWithProviders} from '@angular/core'
import {CommonModule} from '@angular/common'

import {UserService} from './services/user.service'
import {SynchService} from 'app/c2-core/apis/user/services/synch.service'
import {TermsService} from './services/terms.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [UserService, SynchService, TermsService],
  exports: [],
})
export class UserAPI {
  static forRoot(): ModuleWithProviders<UserAPI> {
    return {
      ngModule: UserAPI,
      providers: [UserService, TermsService],
    }
  }
}
