import {DatePickerDateModel} from './datepicker.date.model'
import {ReplaceTokens} from './replaceTokens'

export class BaseModel {
  constructor() {}

  safeValueOrNull(val: any) {
    if (val == null || val === undefined) {
      return null
    }
    return val
  }

  /**
   *  Returns an empty array or an array filled with
   *  elements of type T.
   *
   *  NOTE: assumes that all types passed in
   *  have a copy constructor and that the
   *  parameter val can be used to pass in.
   */
  safeValueOrNewArray<T>(val: any, type: {new (X): T}) {
    const safeArray = new Array<T>()

    if (val == null || val === undefined) {
      return safeArray
    }

    for (let _i = 0; _i < val.length; _i++) {
      safeArray.push(new type(val[_i]) as T)
    }

    return safeArray
  }

  /**
   *  Returns an empty array or an array filled with
   *  elements of type T.
   *
   *  NOTE: assumes that all types passed in
   *  have a copy constructor and that the
   *  parameter val is a string array
   */
  safeValueOrNewStringArray(val: any) {
    const safeArray = new Array<string>()

    if (val == null || val === undefined) {
      return safeArray
    }

    for (let _i = 0; _i < val.length; _i++) {
      safeArray.push(val[_i])
    }

    return safeArray
  }

  fromFirestoreDate(firestoreDate: any) {
    if (!firestoreDate) {
      return null
    }

    let converted = null
    try {
      converted = firestoreDate.toDate()
    } catch (e) {
      converted = new Date(firestoreDate)
    }

    return converted
  }
  /**
   * Returns a new date based on source or new Date()
   * if source is null or undefined.
   *
   * @param source
   */
  createDate(source: any) {
    if (source != null || source !== undefined) {
      return new Date(source)
    }

    return new Date()
  }

  /**
   * Returns a new date based on source or null
   * if source is null or undefined.
   *
   * @param source
   */
  createDateOrNull(source: any) {
    if (source != null && source !== undefined) {
      return new Date(source)
    }

    return null
  }

  /**
   * Converts a Date object to an object
   * suitable for the apps date picker.
   *
   *
   * @param jsdate
   *
   * @returns {DatePickerDateModel}
   */
  toDatePickerDate(jsdate: Date): DatePickerDateModel {
    return new DatePickerDateModel(jsdate)
  }

  /**
   * Create an empty date picker date model
   */
  toDatePickerEmptyDate(): DatePickerDateModel {
    let dpd = new DatePickerDateModel(null)
    dpd.date = null
    return dpd
  }

  /**
   * Compares two values, considers null and undefined as the same
   *
   *
   * @param v1
   * @param v1
   */
  compare(v1: any, v2: any) {
    if (v1 == v2) {
      return true
    }

    if ((v1 == null || v1 === undefined) && (v2 == null || v2 === undefined)) {
      return true
    }

    return false
  }

  /**
   * Scrubs text for provisioning, replaces all tokens
   * in the templates with regex from ReplaceTokens.
   *
   * Retrieves all string properties using reflection,
   * since this looks at all string properties it doesn't
   * discriminate between actual template properties or
   * others, if other string properties happen to contain
   * a token, it will be replaced. All tokens have the form
   * {{TOKEN_NAME}}
   *
   * If a class contains only primitaves it does not need
   * to override this method, if a class contains other
   * BaseModel derived classes or Arrays it should override
   * this method ex:
   *
   *      scrub(replaceTokens: ReplaceTokens) {}
   *      super.scrub(replaceTokens);
   *      that.scrub(replaceTokens); // another BaseModel derived class
   *      super.scrubArray(thatArray); // an Array of BaseModel derived classes
   * }
   *
   * @param replaceTokens
   */
  scrub(replaceTokens: ReplaceTokens) {
    const obj = this
    const array = Object.getOwnPropertyNames(obj)

    array.forEach(prop => {
      if (obj[prop] != null && obj[prop] !== undefined && typeof obj[prop] === 'string') {
        obj[prop] = replaceTokens.replaceAll(obj[prop])
      }
    })
  }

  /**
   * Scrubs an array that contains elements
   * derived from BaseModel.
   *
   * @see this.scrub() for more information
   *
   * @param array
   * @param replaceTokens
   */
  scrubArray(array: any, replaceTokens: ReplaceTokens) {
    if (array != null && array !== undefined) {
      for (let _i = 0; _i < array.length; _i++) {
        let obj = array[_i] as BaseModel
        obj.scrub(replaceTokens)
      }
    }
  }
}
