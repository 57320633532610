import {Component, OnInit, OnDestroy} from '@angular/core'
import {Routes} from '@angular/router'
import {SYSTEM_ROLES} from '../c2-core/core.constants'
import {BaMenuService} from '../theme'
import {PAGES_MENU} from './pages.menu'
import {BASE_MENU} from './pages.menu'
import {SubscriptionManager} from 'app/c2-core/services/subscription.manager'
import {AuthService} from 'app/c2-core/services/auth.service'
import {GlobalState} from 'app/global.state'

@Component({
  selector: 'pages',
  template: `
    <ba-sidebar *ngIf="!hideMenu"></ba-sidebar>
    <ba-page-top></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-main clearfix">
        <!--<div class="al-copy">&copy; <a href="http://concentriccompliance.com" translate>{{'general.justpolicies'}}</a> 2020</div>-->
        <div class="al-copy">
          &copy; <a href="http://concentriccompliance.com" translate>{{ 'ConcentricCompliance' }}</a> 2020
        </div>
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
  `,
})
export class Pages implements OnInit, OnDestroy {
  subs: SubscriptionManager = new SubscriptionManager()
  hideMenu: boolean = false

  constructor(private _menuService: BaMenuService, private _state: GlobalState, private authService: AuthService) {}

  ngOnInit() {
    this.authService.getAllUserRoles(SYSTEM_ROLES).then(userRoles => {
      let filteredMenu = BASE_MENU.slice()
      PAGES_MENU[0].children.forEach(menuItem => {
        if (menuItem.data && menuItem.roles) {
          if (menuItem.roles.every(i => userRoles.includes(i))) {
            filteredMenu[0].children.push(menuItem)
          }
        }
      })
      this._menuService.updateMenuByRoutes(<Routes>filteredMenu)
      if (filteredMenu[0].children.length < 2) {
        this.hideMenu = true
        this._state.notifyDataChanged('menu.isCollapsed', true)
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }
}
