import {NgModule, ModuleWithProviders} from '@angular/core'
import {CommonModule} from '@angular/common'
import {CustomerService} from './services/customers.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [CustomerService],
  exports: [],
})
export class CustomerAPI {
  static forRoot(): ModuleWithProviders<CustomerAPI> {
    return {
      ngModule: CustomerAPI,
      providers: [CustomerService],
    }
  }
}
