import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap'

//https://ng-bootstrap.github.io/#/components/datepicker

export class DatePickerDateModel {
  date: NgbDateStruct = {year: 0, month: 0, day: 0}

  constructor(jsdate: Date) {
    const baseDate: Date = jsdate ? jsdate : new Date()
    this.date.year = baseDate.getFullYear()
    this.date.month = baseDate.getMonth() + 1 // JS Date starts at zero, NgbDateStruct starts at 1
    this.date.day = baseDate.getDate()
  }

  public jsdate(): Date {
    if (this.date == null) {
      return null
    }

    return new Date(this.date.year, this.date.month - 1, this.date.day)
  }
}
