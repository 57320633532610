<aside class="al-sidebar" sidebarResize>
  <table class="table" *ngIf="customer && customer.logoImageURL">
    <tr class="text-center">
      <td class="logo-container no-top-bdr">
        <img class="client-logo" src="{{ customer.logoImageURL }}" alt="" />
      </td>
    </tr>
  </table>
  <ba-menu [menuHeight]="menuHeight" [sidebarCollapsed]="isMenuCollapsed" (expandMenu)="menuExpand()"> </ba-menu>
</aside>
