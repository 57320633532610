import {Injectable} from '@angular/core'
import {AppConfig} from '../models/config.model'
import {AngularFirestore} from '@angular/fire/firestore'
import {DataSchema} from './data.schema'

@Injectable()
export class ConfigService {
  appConfig: AppConfig

  /**
   * NOTE: Use AngularFire directly in this service becuase data.service.ts needs this service
   * as a dependency.
   */

  constructor(private afs: AngularFirestore) {
    /**
     * No good way to unsubscribe, comment for now
     */
    /*let schema = new DataSchema();
        this.afs.doc<AppConfig>(schema.COLLECTION_APP_CONFIG).valueChanges().subscribe( doc => {
            this.appConfig = new AppConfig(doc);
        });*/
  }

  public getReadOnlyMode(): boolean {
    if (this.appConfig) {
      return this.appConfig.readOnlyMode
    }
    return false
  }
}
