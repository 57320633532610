import {BaseModel} from '../../base/base.model'

export class TermsOfUse extends BaseModel {
  id: string
  publishDate: Date
  terms: string
  version: number
  currentVersion: boolean

  constructor(terms: any) {
    super()
    this.copy(terms)
  }

  copy(that: any) {
    if (that != null && that !== undefined) {
      this.id = super.safeValueOrNull(that.id)
      this.publishDate = super.fromFirestoreDate(that.publishDate)
      this.terms = super.safeValueOrNull(that.terms)
      this.version = super.safeValueOrNull(that.version)
      this.currentVersion = super.safeValueOrNull(that.currentVersion)
    }
  }

  toJSON() {
    return {
      id: this.id,
      publishDate: this.publishDate,
      terms: this.terms,
      version: this.version,
      currentVersion: this.currentVersion,
    }
  }
}
