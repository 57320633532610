// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAPFlP4BXMOkRbhmLLQ6JaLmJGOgK_BOv0',
    authDomain: 'c2-ci-248719.firebaseapp.com',
    databaseURL: 'https://c2-ci-248719.firebaseio.com',
    projectId: 'c2-ci-248719',
    storageBucket: 'c2-ci-248719.appspot.com',
    messagingSenderId: '939005389092',
    appId: '1:939005389092:web:9d223bfdd9cc72e4a52bb1',
    measurementId: 'G-1PQWCVDFNK',
  },
  rollbarConfig: {
    accessToken: 'replace_me',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: 'ci',
      server: {
        root: 'webpack:///',
      },
      client: {
        root: 'webpack:///',
        javascript: {
          source_map_enabled: true, // true by default
          // -- Add this into your configuration ---
          code_version: 'replace_me',
          // ---------------------------------------

          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true,
        },
      },
    },
  },
}
