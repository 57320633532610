import {Injectable} from '@angular/core'
import {Observable} from 'rxjs/Rx'
import {map} from 'rxjs/operators'
import {AuthService} from 'app/c2-core/services/auth.service'

import {DataService} from '../../../services/data.service'
import {BaseService} from '../../base/base.service'

import {CustomerUser} from '../models/customer-user.model'
import {Role} from '../models/role.model'
import {DistributionList} from '../models/distribution-list.model'
import {ROLE_MAP, SYSTEM_ROLES} from 'app/c2-core/core.constants'
import {TermsOfUse} from '../models/terms-of-use.model'
export const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

@Injectable()
export class UserService extends BaseService {
  readonly USER_STATE_DISABLED = 'Disabled'
  readonly USER_STATE_INVITED = 'Invited'
  readonly USER_STATE_REGISTERED = 'Registered'
  readonly USER_STATE_UPLOADED = 'Uploaded'
  readonly ORIGIN_SOURCE_UPLOAD = 'upload'

  constructor(private dataService: DataService, private authService: AuthService) {
    super()
  }

  /**
   * List all users for a given customer
   *
   *
   */
  public listUsers(customerKey: string): Observable<Array<CustomerUser>> {
    return this.dataService
      .fetch<CustomerUser>(this.dataService.COLLECTION_USERS, ref => {
        return ref.where('customerKey', '==', customerKey)
      })
      .pipe(map(list => super.mapList(list, CustomerUser)))
  }

  /**
   * Find a list of all users with a subject matter expert role
   *
   * @param customerKey
   */
  public listSubjectMatterExperts(customerKey: string): Promise<Array<CustomerUser>> {
    const SME = ROLE_MAP + SYSTEM_ROLES.sme
    return this.dataService
      .fetchOnce<CustomerUser>(this.dataService.COLLECTION_USERS, ref => {
        return ref.where('customerKey', '==', customerKey).where(SME, '==', true)
      })
      .then(users => {
        // console.log(users)
        return Promise.resolve(super.mapList(users, CustomerUser))
      })
      .catch(err => Promise.reject(err))
  }

  /**
   * List all roles that are assignable
   *
   */
  public listRoles(): Observable<Array<Role>> {
    return this.dataService.list(this.dataService.PATH_ROLES, 'assignable', 'true').pipe(map(list => super.mapList(list, Role)))
  }

  /**
   * Returns a list of distribution lists
   *
   *
   * @param customerKey
   */
  public listDistributionLists(customerKey: string): Observable<Array<DistributionList>> {
    return this.dataService
      .fetch<DistributionList>(this.dataService.COLLECTION_DISTRIBUTION_LIST, ref => {
        return ref.where('customerKey', '==', customerKey)
      })
      .pipe(map(list => super.mapList(list, DistributionList)))
  }

  public addCustomerUser(profile: CustomerUser): Promise<void> {
    const id = this.dataService.createId()
    profile.id = id
    profile.originSource = profile.ORIGIN_SOURCE_WEB
    const path = this.dataService.COLLECTION_USERS + id
    this.makeUserSearchable(profile)
    return this.dataService.setDocument(path, profile.toJSON()).catch(err => Promise.reject(err))
  }

  public enableOrDisableCustomerUser(profile: CustomerUser): Promise<any> {
    if (profile.disabled) {
      profile.disabled = null
      profile.isDisabled = false
    } else {
      profile.disabled = new Date()
      profile.isDisabled = true
    }

    // NOTE: A cloud function enables or disables the auth account
    return this.saveCustomerUser(profile).catch(err => Promise.reject(err))
  }

  public saveCustomerUser(profile: CustomerUser): Promise<void> {
    const path = this.dataService.COLLECTION_USERS + profile.id
    this.makeUserSearchable(profile)
    return this.dataService.updateDocument(path, profile.toJSON()).catch(err => Promise.reject(err))
  }

  /**
   * Makes user searchable in the app
   *
   * @param {*} user
   */
  private makeUserSearchable(user: CustomerUser) {
    // role map
    user.roleMap = {}
    user.roles.forEach(role => {
      user.roleMap[role] = true
    })

    // isDisabled
    if (user.isDisabled) {
      user.state = this.USER_STATE_DISABLED
    } else if (user.invited && !user.registered) {
      user.state = this.USER_STATE_INVITED
    } else if (user.registered) {
      user.state = this.USER_STATE_REGISTERED
    }
  }

  public userState(user) {
    let state = ''
    if (user.isDisabled) {
      state = this.USER_STATE_DISABLED
    } else if (user.invited && !user.registered) {
      state = this.USER_STATE_INVITED
    } else if (user.registered) {
      state = this.USER_STATE_REGISTERED
    } else if (!user.registered && !user.authId && user.originSource === this.ORIGIN_SOURCE_UPLOAD) {
      state = this.USER_STATE_UPLOADED
    } else if (user.authId) {
      state = this.USER_STATE_REGISTERED
    }
    return state
  }

  public inviteUser(invite: CustomerUser): Promise<any> {
    invite.authId = null
    invite.invited = new Date()
    invite.state = 'Invited'
    return this.addCustomerUser(invite).catch(err => Promise.reject(err))
  }

  public saveInvitedUser(invite: CustomerUser): Promise<any> {
    return this.saveCustomerUser(invite).catch(err => Promise.reject(err))
  }

  public deleteInvitedUser(invite: CustomerUser): Promise<any> {
    const path: string = this.dataService.COLLECTION_USERS + invite.id
    return this.dataService.deleteDocument(path)
  }

  public async findUserProfile(): Promise<CustomerUser> {
    try {
      const appUserId = await this.authService.getAppUserId()
      if (!appUserId) return null
      const user = await this.dataService.getDocumentOnce(this.dataService.COLLECTION_USERS, appUserId)
      return new CustomerUser(user)
    } catch (e) {
      throw e
    }
  }

  /**
   * Get the current terms of use
   */
  public getTermsOfUse(): Promise<TermsOfUse> {
    return this.dataService
      .fetchOnce<TermsOfUse>(this.dataService.COLLECTION_TERMS_OF_USE, ref => {
        return ref.where('currentVersion', '==', true)
      })
      .then(termsList => {
        return Promise.resolve(super.mapObject(termsList[0], TermsOfUse, null))
      })
      .catch(err => Promise.reject(err))
  }

  /**
   * Find a terms of use document
   */
  public findTermsOfUse(id: string): Observable<TermsOfUse> {
    const path = this.dataService.COLLECTION_TERMS_OF_USE + id
    return this.dataService.getDocument(path).pipe(map(doc => super.mapObject(doc, TermsOfUse, path)))
  }

  /**
   * Validate an email
   *
   */
  public validateEmail(email: string): boolean {
    if (email == null || email === undefined) {
      return false
    }
    return new RegExp(REGEX_EMAIL).test(email)
  }
}
