import {Injectable} from '@angular/core'
import {environment} from '../../../environments/environment'

@Injectable()
export class LogService {
  public error(err: Error, msg?: string) {
    if (environment.production) {
      return
    }
    if (err) {
      console.error(err.message)
      console.error(err.stack)
    }
    if (msg) {
      console.error(msg)
    }
  }

  public debug(msg: string) {
    if (environment.production) {
      return
    }
    console.log(msg)
  }
}
