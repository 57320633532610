import {NgModule, ModuleWithProviders} from '@angular/core'
import {CommonModule} from '@angular/common'

import {AuthService} from './services/auth.service'
import {DataService} from './services/data.service'
import {DataServiceFirebase} from './services/data.firebase.service'
import {DataServiceFirestore} from './services/data.firestore.service'
import {StorageService} from './services/storage.service'
import {LogService} from './services/log.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [AuthService, DataService, DataServiceFirebase, DataServiceFirestore, StorageService, LogService],
  exports: [],
})
export class C2CoreModule {
  static forRoot(): ModuleWithProviders<C2CoreModule> {
    return {
      ngModule: C2CoreModule,
      providers: [AuthService, DataService, StorageService, LogService],
    }
  }
}
