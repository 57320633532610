export class BaseService {
  constructor() {}

  /**
   * Maps a [list: any] to a typed Array<>.
   * Assumes a copy constructor on the model.
   *
   * @param list
   * @param type
   *
   * @returns {Array<T>}
   *
   * Example Usage:
   *
   *  return this.dataService.list(this.dataService.PATH_POLICIES + customerKey)
   *       .map(list => super.mapList(list, PolicyHeader));
   */
  mapList<T>(list: any, type: {new (X): T}): Array<T> {
    const typedList = new Array<T>()
    for (let i = 0; i < list.length; i++) {
      typedList.push(new type(list[i]) as T)
    }
    return typedList
  }

  /**
   * Maps an object to a model type, assumes a copy constrcutor on the model.
   * @param obj
   * @param type
   */
  mapObject<T>(obj: any, type: {new (X): T}, path: string) {
    const mapObj: any = new type(obj) as T
    mapObj.path = path
    return mapObj
  }
}
