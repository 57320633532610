import {Component, ElementRef, HostListener, OnInit, OnDestroy} from '@angular/core'
import {GlobalState} from '../../../global.state'
import {layoutSizes} from '../../../theme'
import {CustomerService} from 'app/c2-core/apis/customer/services/customers.service'
import {Customer} from 'app/c2-core/apis/customer/models/customer.model'
import {AuthService} from 'app/c2-core/services/auth.service'
import {SubscriptionManager} from 'app/c2-core/services/subscription.manager'

@Component({
  selector: 'ba-sidebar',
  templateUrl: './baSidebar.html',
  styleUrls: ['./baSidebar.scss'],
})
export class BaSidebar implements OnInit, OnDestroy {
  public menuHeight: number
  public isMenuCollapsed: boolean = false
  public isMenuShouldCollapsed: boolean = false
  customer: Customer
  subs: SubscriptionManager = new SubscriptionManager()

  constructor(
    private _elementRef: ElementRef,
    private _state: GlobalState,
    private customerService: CustomerService,
    private authService: AuthService
  ) {
    this._state.subscribe('menu.isCollapsed', isCollapsed => {
      this.isMenuCollapsed = isCollapsed
    })

    // Added by dsmith to always make the menu collapse
    // after it's clicked on a small screen
    this._state.subscribe('menu.activeLink', () => {
      if (window.innerWidth <= layoutSizes.resWidthCollapseSidebar) {
        this.menuCollapse()
      }
    })
    ///////////////////////////////////////
  }

  public ngOnInit(): void {
    this.authService.getCustomerKey().then(customerKey => {
      ;(this.subs.next = this.customerService.find(customerKey).subscribe(customer => {
        this.customer = customer
      })),
        err => {
          err.c2Message = 'Unable to find customer.'
          throw err
        }
    })

    if (this._shouldMenuCollapse()) {
      this.menuCollapse()
    }
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.updateSidebarHeight())
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    var isMenuShouldCollapsed = this._shouldMenuCollapse()

    if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
      this.menuCollapseStateChange(isMenuShouldCollapsed)
    }
    this.isMenuShouldCollapsed = isMenuShouldCollapsed
    this.updateSidebarHeight()
  }

  public menuExpand(): void {
    this.menuCollapseStateChange(false)
  }

  public menuCollapse(): void {
    this.menuCollapseStateChange(true)
  }

  public menuCollapseStateChange(isCollapsed: boolean): void {
    this.isMenuCollapsed = isCollapsed
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed)
  }

  public updateSidebarHeight(): void {
    // TODO: get rid of magic 84 constant
    this.menuHeight = this._elementRef.nativeElement.childNodes[0].clientHeight - 84
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= layoutSizes.resWidthCollapseSidebar
  }
}
